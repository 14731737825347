<template>
  <div>Calving</div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style></style>
